<template>
   <el-dialog :title="dialogTitles" :visible.sync="isShows" :before-close="handleCloses" width="550px">
      <el-form class="form-dialog-box" :model="ruleForm" :rules="rules" ref="ruleForm">
         <el-form-item label="姓名：" prop="realName">
            <el-input class="width-300" v-model="ruleForm.realName" placeholder="请输入姓名" clearable></el-input>
         </el-form-item>
         <el-form-item label="手机号码：" prop="mobile">
            <el-input class="width-300" v-model="ruleForm.mobile" placeholder="请输入手机号" clearable></el-input>
         </el-form-item>
         <el-form-item label="证件类型：" prop="certificateType">
            <el-select class="width-300" v-model="ruleForm.certificateType" placeholder="请选择证件类型" clearable>
               <el-option
                     v-for="(item,index) in dictData['certificate-type']"
                     :key="index"
                     :label="item"
                     :value="index">
               </el-option>
            </el-select>
         </el-form-item>
         <el-form-item label="证件号：" prop="certificateNo">
            <el-input class="width-300" v-model="ruleForm.certificateNo" placeholder="请输入身份证号码" clearable></el-input>
         </el-form-item>
         <el-form-item label="地址">
            <el-cascader @change="handleChange" class="width-300" v-model="ruleForm.addressIds"
                         :props="addressProp"></el-cascader>
         </el-form-item>
         <el-form-item prop="fullAddress">
            <el-input class="width-300" v-model="ruleForm.fullAddress" placeholder="请输入详细地址" clearable maxlength="60"
                      minlength="5"></el-input>
            <div style="color: #999999;">请输入详细的籍贯地址，最短5个字，最长60个字</div>
         </el-form-item>
         <el-form-item label="行业：" prop="industry">
            <el-input class="width-300" v-model="ruleForm.industry" placeholder="请输入行业" clearable></el-input>
         </el-form-item>
         <el-form-item label="备注：">
            <el-input type="textarea" class="width-300" v-model="ruleForm.remark" maxlength="300" rows="4"
                      show-word-limit clearable></el-input>
         </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
         <el-button @click="handleCloses">取消</el-button>
         <el-button class="bg-gradient" type="primary" @click="submitForm('ruleForm')">确定</el-button>
      </div>
   </el-dialog>
</template>

<script>
import {getDict, verificationRule} from '@/common/js/common'
import {urlObj} from '@/api/interface'
import {mapState} from "vuex";
export default {
   name: "handleDialog",
   props:{
      dialogTitle:{
        type:String,
        default: ''
      },
      isShow:{
         type:Boolean,
         default:false,
      },
      editData:{
         type:Object,
         default: function (){
            return {}
         },
      }
   },
   watch:{
      dialogTitle(){
         this.dialogTitles = this.dialogTitle
         if(this.dialogTitles === '编辑'){
            console.log(this.editData)
            for (const k in this.ruleForm) k in this.editData && (this.ruleForm[k] = this.editData[k])
            this.ruleForm.addressIds = [this.editData.province, this.editData.city, this.editData.address]
         }
      },
      isShow(){
         this.isShows = this.isShow
      },
   },
   computed: {
      ...mapState(['dictData', 'hotelInfo']),
   },
   data(){
      const _this = this
      let validName = (rule, value, callback) => {
         if (value === '') {
            callback(new Error('请输入姓名'))
         } else if (verificationRule.scopeLength(value, [2, 6])) {
            callback(new Error('必填2-6个字，不允许空格，字符，数字'))
         } else {
            callback()
         }
      }
      //电话
      let validMobile = (rule, value, callback) => {
         if (value === '') {
            callback(new Error('请输入手机号'))
         } else if (verificationRule.mobile(value)) {
            callback(new Error('手机号格式错误'))
         } else {
            callback()
         }
      }
      //身份证号
      let validCard = (rule, value, callback) => {
         if (value === '') {
            callback(new Error('请输入身份证号'))
         } else if (verificationRule.certificateNo(value)) {
            callback(new Error('证件号格式错误'))
         } else {
            callback()
         }
      }
      // 详细地址
      let validFullAddress = (rule, value, callback) => {
         if (value === '') {
            callback(new Error('请输入身份证号'))
         } else if (verificationRule.minLength(value, [5])) {
            callback(new Error('最短需要输入5个字,不允许空格'))
         } else {
            callback()
         }
      }
      return{
         dialogTitles:'',
         isShows:false,
         ruleForm:{
            realName: '',
            mobile: '',
            industry: '',
            source: 'PLATFORM',
            hotelId: '',
            city: '',                  //市
            province: '',              //省
            address: '',               //区
            certificateType: '',       //证件类型
            certificateNo: '',         //证件号
            addressIds: [],            //地址 addressIds
            fullAddress: '',           //详细地址
            remark: "",                //备注
            id:'',
         },

         rules: {
            realName: [{required: true, validator: validName, trigger: 'blur'}],
            mobile: [{required: true, validator: validMobile, trigger: 'blur'}],
            certificateType: [{required: true, message: '请选择证件类型', trigger: 'blur'}],
            certificateNo: [{required: true, validator: validCard, trigger: 'blur'}],
            addressIds: [{type: 'array', required: true, message: '请选择地址', trigger: 'blur'}],
            // fullAddress:[{ required: true, validator: validFullAddress, trigger: 'blur' }]
         },

         addressProp: {    // 地域列表
            lazy: true,
            checkStrictly: true,
            async lazyLoad(node, resolve) {
               let {value, hasChildren, level} = node
               if (hasChildren === false) return resolve([])
               if (value) {
                  value = value.split(',')[0]
               } else {
                  value = -1
               }
               let data = await _this.getRegionList(value, level)
               resolve(data)
            }
         },
         add_success :'添加成功',
         update_success:'编辑成功',
      }
   },
   mounted() {
      getDict(["certificate-type"])
      this.ruleForm.hotelId = this.hotelInfo.id
   },
   methods:{
      // 关闭
      handleCloses(){
         for (const k in this.ruleForm)this.ruleForm[k] = ''
         this.ruleForm.source = 'PLATFORM'
         this.ruleForm.addressIds = []     //地址 addressIds
         this.ruleForm.hotelId = this.hotelInfo.id
         this.$emit('handleClose',false)
      },

      // 提交
      submitForm(formName) {
         this.$refs[formName].validate((valid) => {
            if (valid) {
               let url = urlObj.addMember
               let param = this.ruleForm
               if (this.dialogTitles === '编辑') {
                  url = urlObj.editMember
               }
               this.$axios.post(url,param).then(res => {
                  if (res.success) {
                     this.$message({
                        showClose: true,
                        message: this.action === 'add' ? this.add_success : this.update_success,
                        type: 'success'
                     })
                     this.$emit('Refresh')
                     this.handleCloses()
                  }
               })
            } else {
               this.$message('请完善信息');
               return false;
            }
         });
      },

      // 地址
      handleChange(value){
         let [province, city, district] = value
         if (province) {
            this.ruleForm.province = province
         }
         if (city) {
            this.ruleForm.city = city
         }
         if (district) {
            this.ruleForm.address = district
         }
      },
      // 获取省市县
      getRegionList(parentId = '-1') {
         return new Promise(resolve => {
            const url = urlObj.regionList + `/${parentId}`
            this.$axios.get(url, {}).then(res => {
               if (res.success) {
                  let addressList = res.children
                  addressList = addressList.map(item => {
                     return {value: item.id + ',' + item.regionName, label: item.regionName}
                  })
                  resolve(addressList)
               }
            })
         })
      },

   }
}
</script>

<style scoped>

</style>